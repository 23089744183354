import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Lucas, o Mestre do Universo.
        </p>
        <p>
          Marcos, o Deus do DevOps.
        </p>
        <p>
          João, o Duque da Cerveja.
        </p>
      </header>
    </div>
  );
}

export default App;
